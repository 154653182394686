.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 3rem 4rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.89) 0%, #f5f5f5 47.92%, #e9e9e9 100%);
}

.border {
  width: 100%;
  height: 0.2rem;
  background-color: #ffffff;
}
