@import '../../theme/variables';

span {
  font-weight: $font-bold;
}

.weather {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__actual {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1rem 0;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }

  &__icon {
    width: 6rem;
    height: 6rem;
    margin-right: 2rem;
  }

  &__iconSmall {
    width: 4.1rem;
    height: 4.1rem;
    margin-right: 1.5rem;
  }

  &__name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-bold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    letter-spacing: 0.075rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid #ffffff;
  }

  &__temperature {
    font-weight: 600;
    font-size: 5rem;
    line-height: 7rem;
  }

  &__text {
    font-weight: $font-regular;
    font-size: 2rem;
    line-height: 2.4rem;
  }

  &__paragraph {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
