@import '../../theme/variables';

.clock {
  letter-spacing: 0.075rem;

  &__time {
    font-size: 6rem;
    line-height: 7.2rem;
    text-align: center;
    font-weight: 600;
  }

  &__paragraph {
    text-transform: capitalize;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
    font-weight: 600;
  }
}
