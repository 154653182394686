@import '../../theme/variables';

.logo {
  display: flex;
  justify-content: center;
  width: 100%;

  &__logo {
    width: auto;
    max-width: 28rem;
    max-height: 14rem;
  }
}
